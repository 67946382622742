var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ProjectPageTemplate',{staticStyle:{"position":"relative","height":"100%","width":"100%"},attrs:{"loading":_vm.isLoading,"isSettings":true,"error":false,"title":_vm.$t(_vm.pageTitleTranslator)},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.sortedDataForTable != null && _vm.sortedDataForTable.length > 0)?_c('div',{staticClass:"grey lighten-5 body-content-height overflow-y-auto",staticStyle:{"overflow-x":"hidden !important"}},[(_vm.sortedDataForTable.length > 1)?_c('v-row',{staticClass:"mb-4 mt-3",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"order":"1"}},[_c('LineChartGenerator',{attrs:{"chart-data":_vm.chartData,"chart-options":_vm.configChart.options,"chart-id":_vm.configChart.chartId,"dataset-id-key":_vm.configChart.datasetIdKey,"plugins":_vm.configChart.plugins,"css-classes":_vm.configChart.cssClasses,"styles":_vm.configChart.styles,"width":_vm.configChart.width,"height":_vm.configChart.height}})],1)],1):_vm._e(),(_vm.sortedDataForTable.length > 1)?_c('v-divider',{staticClass:"ma-3 ml-5 mr-5 mb-4"}):_vm._e(),_c('v-row',{staticClass:"mb-5 ml-3 mr-3 mt-3"},[_c('v-col',{},[_c('v-data-table',{staticClass:"rounded table-border",attrs:{"dense":"","hide-default-footer":"","disable-pagination":true,"id":"tb-data-usage","headers":_vm.columns,"items":_vm.sortedDataForTable,"disable-sort":""},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('div',{staticClass:"pt-2 pb-2 font-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("monthFilter")(item.createdAt))+" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(index == 0),expression:"index == 0"}],staticClass:"font-weight-light ml-2"},[_vm._v("("+_vm._s(_vm.$t('tools.project.settings.dataUsage.table.currentMonth'))+")")])])]}},{key:"item.size",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('div',{staticClass:"text-body-3 font-weight-regular d-flex justify-start"},[_c('div',{staticClass:"d-flex text-right w-150"},[_c('div',{staticClass:"w-full"},[_vm._v(" "+_vm._s(_vm._f("formattedDataFilter")(item.size))+" ")]),_c('div',{class:[
                      _vm.accumulatedData[item.createdAt.toString()] === 'START'
                        ? _vm.accumulatedTextStart
                        : _vm.accumulatedTextNumber ],staticStyle:{"margin-top":"2px"}},[_c('span',{class:index == 0 ? 'grey--text' : ''},[_vm._v(_vm._s(_vm.accumulatedData[item.createdAt.toString()]))])])]),_c('div')])]}}],null,true)})],1)],1)],1):_c('div',[_c('v-row',{staticClass:"ml-5 mt-3 grey--text",staticStyle:{"font-size":"14px"},attrs:{"align":"center","justify":"center"}},[_vm._v(" "+_vm._s(_vm.$t('tools.project.settings.dataUsage.noDataFound')))])],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }