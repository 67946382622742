<template>
  <ProjectPageTemplate
  style="position: relative; height: 100%; width: 100%;"

    :loading="isLoading"
    :isSettings="true"
    :error="false"
    :title="$t(pageTitleTranslator)"
  >
    <template v-slot:content class="relative">


      <!--------------------- Line chart Data Usage ---------------------- -->
      <div
        v-if="sortedDataForTable != null && sortedDataForTable.length > 0"
        class="grey lighten-5 body-content-height overflow-y-auto"
        style="overflow-x: hidden !important; "
      >
        <v-row
          v-if="sortedDataForTable.length > 1"
          no-gutters
          class="mb-4 mt-3"
        >
          <v-col order="1">
            <LineChartGenerator
              :chart-data="chartData"
              :chart-options="configChart.options"
              :chart-id="configChart.chartId"
              :dataset-id-key="configChart.datasetIdKey"
              :plugins="configChart.plugins"
              :css-classes="configChart.cssClasses"
              :styles="configChart.styles"
              :width="configChart.width"
              :height="configChart.height"
            />
          </v-col>
        </v-row>

        <v-divider
          v-if="sortedDataForTable.length > 1"
          class="ma-3 ml-5 mr-5 mb-4"
        />

        <!-- -------------------- Table Data Usage ---------------------- -->
        <v-row class="mb-5 ml-3 mr-3 mt-3">
          <!-- <v-col class="rounded table-border elevation-4"> -->
          <v-col class="">
            <v-data-table
              dense
              hide-default-footer
              :disable-pagination="true"
              id="tb-data-usage"
              :headers="columns"
              :items="sortedDataForTable"
              class="rounded table-border"
              disable-sort
            >
              <template v-slot:[`item.createdAt`]="{item, index}">
                <div class="pt-2 pb-2 font-weight-bold">
                  {{ item.createdAt | monthFilter }} 
                  <span v-show="index == 0" class="font-weight-light ml-2"
                    >({{
                      $t('tools.project.settings.dataUsage.table.currentMonth')
                    }})</span
                  >
                </div>
              </template>
              <template v-slot:[`item.size`]="{item, index}">
                <div
                  class="text-body-3 font-weight-regular d-flex justify-start"
                >
                  <div class="d-flex text-right w-150">
                    <div class="w-full">
                      {{ item.size | formattedDataFilter }}
                    </div>

                    <div
                      style="margin-top: 2px"
                      :class="[
                        accumulatedData[item.createdAt.toString()] === 'START'
                          ? accumulatedTextStart
                          : accumulatedTextNumber,
                      ]"
                    >
                      <span :class="index == 0 ? 'grey--text' : ''">{{
                        accumulatedData[item.createdAt.toString()]
                      }}</span>
                    </div>
                  </div>
                  <div></div>
                </div>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <v-row
          class="ml-5 mt-3 grey--text"
          align="center"
          justify="center"
          style="font-size: 14px"
        >
          {{ $t('tools.project.settings.dataUsage.noDataFound') }}</v-row
        >
      </div>
    </template>
  </ProjectPageTemplate>
</template>

<script>
/* eslint-disable no-debugger */
import {useRoute, useI18n, useStore, useApi} from '@/plugins/vueUseMethods';

import {formattedDataFilter} from '@/common/filters/formattedData.filter';

import {ref, computed, onMounted} from '@vue/composition-api';

import {format} from 'date-fns';
import {enUS, de} from 'date-fns/locale';
import prettyBytes from 'pretty-bytes';

import ProjectPageTemplate from '@/components/tools/project/ProjectPageTemplate.vue';

import {Line as LineChartGenerator} from 'vue-chartjs/legacy';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
  Filler,
} from 'chart.js';

// -----------------------------------
// insert dataSample for testing
// -----------------------------------
// import { dataApi } from './data';

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
  Filler,
);

export default {
  name: 'ProjectViewUsage',

  components: {
    ProjectPageTemplate,
    LineChartGenerator,
  },

  setup() {
    const route = useRoute();
    const store = useStore();
    const api = useApi();
    const {translate, $i18n} = useI18n();

    
    // =======================================================================
    // First loading to fetch data from server
    // =======================================================================
    const isLoading = ref(true);

    async function _fetchProjectDataUsage() {
      try {
        const res = await api.project.fetchProjectDataUsage(
          route.params.projectId,
        );
        
        
        if (res == null || res.data == null || res.data.dataUsages == null || res.data.dataUsages.length == 0) {
            throw new Error();
        }
        // console.log(res)

        store.commit('setDataUsage', res.data);

      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    }

    onMounted(async () => {
      await _fetchProjectDataUsage();
    });


    // =======================================================================
    // Get data from store vuex
    // =======================================================================
    const dataUsageApi = computed(() => {
      return store.getters.projectUsage;
    });

    const dataSource = computed(() => {
      let data = [];

      if (dataUsageApi.value) {
        data = filterAndGroupData(dataUsageApi.value.dataUsages);
      }
      return data;
    });
    // ======================================================================
    // Filtering and grouping data
    // ======================================================================
    function filterAndGroupData(dataSource) {
      let source = {};
      let comparedMonth;

      for (let i = 0; i < dataSource.length; i++) {
        comparedMonth = new Date(dataSource[i].createdAt).getMonth() + 1;

        source[String(comparedMonth)] = dataSource[i];

        if (!dataSource[i + 1]) {
          break;
        }

        const nextMonth = new Date(dataSource[i + 1].createdAt).getMonth() + 1;

        if (comparedMonth === nextMonth) {
          source[String(comparedMonth)] = dataSource[i + 1];
        }
      }

      const result = Object.values(source)

      return result;
    }

    // ======================================================================
    // For Translating
    // ======================================================================
    const pageTitleTranslator = ref(`${route.meta.i18n}.title`);

    const tableTranslator = ref({
      month: 'tools.project.settings.dataUsage.table.month',
      accumulatedData: 'tools.project.settings.dataUsage.table.accumulatedData',
    });

    // ======================================================================
    // Config for Chart data usage
    // ======================================================================

    // --------------------------------------
    //  Methods use in Chart
    // --------------------------------------
    function _convertTo(data, target) {
      switch (target.toLowerCase()) {
        case 'byte':
          return Number(data) * 1000 * 1024;
        case 'gb':
        default:
          return (Number(data) / 1024).toFixed(2);
      }
    }

    function _formatDataYAxis(data, suffixLetter = false, convertedTarget) {
      let convertedData;
      if (suffixLetter && convertedTarget.toLowerCase() === 'byte') {
        convertedData = _convertTo(data, convertedTarget);
        return prettyBytes(convertedData, {locale: $i18n.locale});
      }
      convertedData = _convertTo(data, convertedTarget);

      return Math.round(convertedData);
    }

    function _formatDataXAxis(data) {
      return format(new Date(data), 'MMM yyyy', {
        locale: $i18n.locale === 'en' ? enUS : de,
        width: 'short',
      });
    }

    function _formatLabelOfChartTooltip(context) {
      let label = '  ';
      // if (label) {
      //   label += ': ';
      // }
      if (context.parsed.y !== null) {
        label += _formatDataYAxis(context.parsed.y, true, 'BYTE');
      }
      return label;
    }

    // --------------------------------------
    //  DataSource for Chart
    // --------------------------------------
    const chartData = computed(() => {
        
      // eslint-disable-next-line
      const dataYAxis = dataSource.value.sort((a, b) => (+ new Date(a.createdAt)) - (+  new Date(b.createdAt))).map((data) => data.size);
      const dataXAxis = dataSource.value.map((data) => {
        return _formatDataXAxis(data.createdAt);
      });
      return {
        labels: dataXAxis,
        datasets: [{...configChart.value.datasets[0], data: dataYAxis}],
      };
    });

    // --------------------------------------
    //  Configuration for Chart
    // --------------------------------------
    const configChart = computed(() => {
      return {
        styles: {},
        cssClasses: '',
        datasetIdKey: 'label',
        chartId: 'line-chart',
        plugins: [],
        datasets: [
          {
            // Label when you hover a point upon line of the chart
            label: 'Data usage',

            // Number of data usage that show at Y-AXIS
            data: [],

            backgroundColor: '#cc202911',
            borderColor: '#cc2029FF',
            lineTension: 0,
            fill: true,
            pointStyle: 'circle',
            pointRadius: 5,
            pointHoverRadius: 7,

            pointBackgroundColor: '#cc2029',
          },
        ],
        options: {
          tooltips: {
            mode: 'dataset',
          },
          hover: {
            intersect: true,
          },
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              grid: {
                color: '#F0F0F0',
              },
              ticks: {
                padding: 9,
                font: {
                  family: 'Lato', // Your font family
                  size: 13,
                },
                color: '#505050',
              },
            },

            yAxis: {
              min: 0,
              grid: {
                color: '#F0F0F0',
              },
              alignToPixels: true,
              display: true,

              title: {
                display: true,
                font: {
                  size: 14,
                },
              },
              position: 'left',
              bounds: 'ticks',
              ticks: {
                font: {
                  family: 'Lato', // Your font family
                  size: 12,
                },
                color: '#898989',

                callback: (data) => _formatDataYAxis(data, false, 'GB') + ' GB',
              },
            },
          },

          plugins: {
            legend: {
              display: false,
            },

            tooltip: {
              titleColor: 'black',

              caretPadding: 20,
              caretSize: 0,
              backgroundColor: '#F0F0F0',
              borderWidth: 1,
              borderColor: '#A0A0A0',
              animation: {
                duration: 0, // general animation time
              },
              hover: {
                animationDuration: 0, // duration of animations when hovering an item
              },
              responsiveAnimationDuration: 0, // animation duration after a resize
              enabled: true,
              titleFont: {
                size: 14,
                family: 'Lato',
              },
              bodyFont: {
                size: 13,
                family: 'Lato',
                color: 'black',
              },
              callbacks: {
                label: (context) => _formatLabelOfChartTooltip(context),
                labelColor(tooltipItem, chart) {
                  return {
                    borderColor: '#F0F0F0',
                    backgroundColor: '#CC2029',
                    borderRadius: 3.5,
                  };
                },
                labelTextColor(tooltipItem, chart) {
                  return 'black';
                },
              },
            },
          },
        },
      };
    });

    // ======================================================================
    // For Table data usage
    // ======================================================================

    // --------------------------------------
    // DataSource is sorted to show
    // the latest month on the table
    // --------------------------------------
    const sortedDataForTable = computed(() => {
      const sortedData = [...dataSource.value];


      sortedData.sort((a, b) => {
        const dateA = new Date(a.createdAt).getTime();
        const dateB = new Date(b.createdAt).getTime();
        return dateA < dateB ? 1 : -1;
      });

      return sortedData;
    });

    const accumulatedData = computed(() => {
      let result = {};
      // const january = 0;

      const arr = sortedDataForTable.value;
      const length = arr.length;

      for (let i = 0; i < length; i++) {
        if (arr[i + 1] == undefined) {
          result[arr[i].createdAt.toString()] = 'START';
          break;
        }
        // if (new Date(arr[i].createdAt).getMonth() === january) {
        //   result[(arr[i].createdAt).toString()] = 'START'
        //   continue;
        // }
        const calculated = formattedDataFilter(arr[i].size - arr[i + 1].size);

        result[arr[i].createdAt.toString()] = `${
          calculated.startsWith('-') ? '-' : '+'
        } ${
          calculated.startsWith('-') ? calculated.split('-')[1] : calculated
        }`;
      }

      return result;
    });

    const accumulatedTextNumber = ref('text-accumulated-data text-red w-110');
    const accumulatedTextStart = ref(
      'text-accumulated-data text-grey-light w-110',
    );

    const columnWidth = computed(() => {
      return 450; //isSmallScreen.value ? 300 : isMediumScreen.value ? 450 : isLargeScreen.value ? 600 : isExtraLargeScreen.value ? 750 : isExtraExtraLargeScreen.value ? 950 : 0
    });

    const columns = computed(() => {
      return [
        {
          text: translate(tableTranslator.value.month),
          width: '100%',
          value: 'createdAt',
          class:
            'text-left font-weight-normal pt-2 pb-2 font-weight-black rounded-tl',
          divider: true,
        },
        {
          text: translate(tableTranslator.value.accumulatedData),
          value: 'size',
          class: 'text-left pt-1 pb-1 font-weight-black rounded-tr',
        },
      ];
    });

    return {
      pageTitleTranslator,
      dataSource,
      chartData,
      configChart,
      columns,
      sortedDataForTable,
      accumulatedData,
      accumulatedTextNumber,
      accumulatedTextStart,
      isLoading,
    };
  },

  /*
    eslint no-unused-vars: 0
  */
};
</script>

<style scoped>
.relative {
  position: relative;
  height: 100%;
}

.loading-area {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.body-content-height {
  height: 100%;
  position: relative;
}

#tb-data-usage >>> tr:hover {
  background-color: #f0f0f099 !important;
}

#tb-data-usage >>> tr > th {
  background: #f0f0f0;
  font-size: 13px;
  color: #808080;
  font-weight: 600 !important;
}

#tb-data-usage >>> tr > th:first-child {
  border-right: 1px solid rgb(198, 198, 198);
}

.table-border {
  border: 1px solid rgb(198, 198, 198);
}

.w-150 {
  max-width: 130px;
}

.w-110 {
  width: 120px;
}

.w-full {
  width: 100%;
}

.text-red {
  color: #cc2029;
}

.text-grey-light {
  color: rgb(198, 198, 198);
}

.text-accumulated-data {
  margin-left: 1rem;
  font-size: 0.75rem;
  font-weight: bold;
}
</style>
