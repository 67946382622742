/* eslint-disable no-unused-vars */
import Vue from 'vue';
import {ref} from '@vue/composition-api';
import {i18n} from './i18n';
import vuetify from './vuetify';
import router from '@/router';
import store from '@/store'

// ================= Initial setup ======================

const routerPlugin = ref({...router})

const routePlugin = ref({
  ...router.currentRoute,
});

router.afterEach((to, from, next) => {
  Object.keys(to).forEach((key) => {
    routePlugin.value[key] = to[key];
  });
});

const authPlugin = ref({...Vue.prototype.$auth});

const apiPlugin = ref({...Vue.prototype.$api});

const pdfPlugin = ref({...Vue.prototype.$pdf});

const helperPlugin = ref({...Vue.prototype.$helper});

// =================== Use methods ======================

export function useRouter() {
  return routerPlugin.value;
}

export function useRoute() {
  return routePlugin.value;
}

export function useStore() {
  return store;
}

export function useVuetify() {
  return vuetify;
}

export function useI18n() {
  const translate = (pathToDefinition) => i18n.t(pathToDefinition);
  const $i18n = i18n;
  return {translate, $i18n}
}

export function useAuth() {
  return authPlugin.value;
}

export function useApi() {
  return apiPlugin.value;
}

export function usePdf() {
  return pdfPlugin.value;
}

export function useHelperPlugin() {
  return helperPlugin.value;
}
